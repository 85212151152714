import { useState, useEffect } from 'react'
import Cookies from 'js-cookie';

/* Components */
import TableView from '../components/TableView/TableView';
import CreateAside from '../components/Asides/CreateAside';

export default function Pages({ context }) {

    const authToken = Cookies.get('auth_token');

    const [pageData, setPageData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCreatePageDialogOpen, setIsCreatePageDialogOpen] = useState(false);

    // Function to handle opening the dialog
    const openCreatePageDialog = () => {
        setIsCreatePageDialogOpen(true);
    };

    // Function to handle closing the dialog
    const closeCreatePageDialog = () => {
        setIsCreatePageDialogOpen(false);
    };

    const handleDeleteSuccess = (itemId) => {
        const updatedPageData = pageData.filter((item) => item.uid !== itemId);
        setPageData(updatedPageData);
    };

    const fetchData = async () => {
        setIsLoading(true);        
        const authToken = Cookies.get('auth_token');
        try {
            const response = await fetch('/api/pages', {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${authToken}`, // Replace with your authentication token
                },
            });

            if (response.ok) {
                const responseData = await response.json();
                const formattedData = responseData.map(item => ({
                    ...item,
                    created_date: new Date(item.created_date).toLocaleDateString(), // Format the created_date
                    last_modified_date: new Date(item.last_modified_date).toLocaleDateString(), // Format the last_modified_date
                }));
                setPageData(formattedData);
                setIsLoading(false);
            } else {
                // Handle errors here
                console.error('Request failed with status:', response.status);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handlePageCreate = async () => {
        await fetchData();
        console.log('Data refreshed');
    };

    useEffect(() => {
        fetchData(); 
    }, [authToken]);

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">
                    <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                        {pageData && (
                            <TableView context={context} data={pageData} onAddButtonClick={openCreatePageDialog} isLoading={isLoading} onDeleteSuccess={handleDeleteSuccess}/>
                        )}
                    </div>
                </div>
            </main>
            <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-l border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block">
            </aside>
            {/* Page */}
            {isCreatePageDialogOpen && (
                <CreateAside
                    type="page"
                    onClose={closeCreatePageDialog}
                    onCreate={handlePageCreate}
                    isDialogOpen={isCreatePageDialogOpen}
                />
            )}
        </>
    )
}