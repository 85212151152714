import React, { useState, useEffect } from 'react';
import FormWizard from '../components/FormWizard/FormWizard';
import SubRouteDisclosureButton from '../components/SubRouteDisclosureButton/SubRouteDisclosureButton';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import DesktopSteps from '../components/DesktopSteps/DesktopSteps';

export default function FormLayout({ previewMode = false, config, assetTitle, assetDescription }) {

  const { uid } = useParams();
  const [fadeIn, setFadeIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({});
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [styleGuide, setStyleGuide] = useState(null);

  // Fetch data when not in previewMode
  useEffect(() => {
    if (!previewMode) {
      const fetchData = async () => {
        try {
          const response = await fetch(`/api/form/${uid}`);
          const data = await response.json();
          setFormData(data.config);
          setTitle(data.title);
          setDescription(data.description);
          setLoading(false);
          if (data.style_guide) {
            setStyleGuide(data.style_guide);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
      fetchData();
    } else {
      setFormData(config);
      setTitle(assetTitle);
      setDescription(assetDescription);
      setLoading(false);
    }
  }, [previewMode, config]);

  useEffect(() => {
    if (!loading && !previewMode) {
      setFadeIn(true);
    }
  }, [loading]);

  return (
    <>
      { }
      <div className="min-h-full">
        <div className={"pb-32 headerContainer bg-gray-400"}
          style={{ backgroundColor: styleGuide?.primary }}>
          <SubRouteDisclosureButton previewMode={previewMode} />
        </div>

        <main className="-mt-36 pb-6">
          <div className="mx-auto max-w-3xl lg:max-w-5xl">
            <div>
              <h1 className="text-2xl font-bold tracking-tight text-white px-4">{title}</h1>
              {/*<p className="text-base text-white px-4 mt-4">{description}</p>*/}
              <h4 className="sr-only">Application steps for {assetTitle}</h4>
            </div>
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8 mt-4">
              <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                {loading ? (
                  <>
                    <div className="mt-4 mx-4 overflow-hidden rounded-lg">
                      <Skeleton className="my-2" count={1} height={40} width={250} />
                      <Skeleton className="my-2" count={1} height={40} width={150} />
                    </div>

                    <section className="mt-4 mx-4 overflow-hidden rounded-lg" >
                      <Skeleton className="my-2" count={1} height={40} width={150} />
                      <Skeleton className="rounded-lg mt-2 mb-4" count={2} height={100} />
                    </section>
                  </>
                ) : (
                  <>
                    <FormWizard formSchema={formData} previewMode={previewMode} />
                  </>
                )}

              </div>
              {!previewMode && (
                <div className="hidden md:block  grid grid-cols-1 gap-4">
                  <DesktopSteps formData={formData} />
                </div>
              )}
            </div>
          </div>
        </main>

      </div>
    </>
  )
}
