import { useEffect } from 'react'

/* Components */
import Apps from '../components/Apps/Apps';

export default function Dashboard({ }) {
    useEffect(() => {
        // Check if the user agent contains 'gonative'
        const isNativeApp = navigator.userAgent.indexOf('gonative') > -1;
        if (isNativeApp) {
            // Use gonative API to change the status bar style and color
            window.gonative.statusbar.set({
                'style': 'light',
                'color': 'FFFFFF',
            });
        }
    }, []);

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">
                    <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                        <Apps />
                    </div>
                </div>
            </main>
        </>
    )
}