import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Cookies from 'js-cookie';

/* mock data and strings */
import { editor_metadata, editor_navigation } from './config/config';

/* pages */
import Find from './pages/Find';
import Hub from './pages/Hub';
import FormLayout from './pages/FormLayout';
import Dashboard from './pages/Dashboard';
import SignIn from './pages/Signin';
import ResetPassword from './pages/ResetAccount';
import Register from './pages/Register';
import Settings from './pages/Settings';
import AppEditor from './pages/AppEditor';
import FlowEditor from './pages/FlowEditor';
import Forms from './pages/Forms';
import Pages from './pages/Pages';
import Events from './pages/Events';
import VendorProfile from './pages/Products';
import PageEditor from './pages/PageEditor';
import FormEditor from './pages/FormEditor_old';
import PageLayout from './pages/PageLayout';

/* utils */
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

function App() {
  const [token, setToken] = useState(Cookies.get('auth_token') || null);

  const handleLogin = (newToken) => {
    setToken(newToken);
    Cookies.set('auth_token', newToken, { secure: true, sameSite: 'strict', expires: 7 });
  };

  const handleLogout = () => {
    setToken(null);
    Cookies.remove('auth_token');
  };

  return (
    <Router basename="/">
      <Routes>
        <Route path="" element={<SignIn onLogin={handleLogin} />} />
        <Route path="/signin" element={<SignIn onLogin={handleLogin} />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {process.env.REACT_APP_SAAS_ENABLED === 'true' ? (
          <Route path="/register" element={<Register />} />
        ) : (
          <Route path="/register" element={<Navigate to="/" />} />
        )}

        <Route path="/app" element={<Find onLogout={handleLogout} previewMode={false} />} />
        <Route path="/a/:uid" exact element={<Hub onLogout={handleLogout}  previewMode={false} />} />
        <Route path="/a/f/:uid" element={<FormLayout onLogout={handleLogout} previewMode={false} />} />
        <Route path="/a/p/:uid" element={<PageLayout onLogout={handleLogout} previewMode={false} />} />
        <Route path="/m/*" element={
            <PrivateRoute authenticated={!!token} redirectTo="/signin" onLogout={handleLogout} navigation={editor_navigation.manage}>
              <Route index element={<Dashboard />} />
              <Route path=":uid" exact element={<AppEditor />} />
              <Route path="2/:uid" exact element={<FlowEditor />} />
              <Route path="pages" element={<Pages context={editor_metadata.PAGE_EDIT} />} />
              <Route path="pages/new" element={<PageEditor context={editor_metadata.PAGES_NEW}/>} />
              <Route path="pages/edit/:uid" element={<PageEditor context={editor_metadata.PAGE_EDIT}/>} />
              <Route path="forms" element={<Forms context={editor_metadata.FORM_EDIT} />} />
              <Route path="forms/new" element={<FormEditor context={editor_metadata.FORMS_NEW}/>} />
              <Route path="forms/edit/:uid" element={<FormEditor context={editor_metadata.FORM_EDIT}/>} />
              <Route path="products" element={<VendorProfile />} />
              <Route path="events" element={<Events context={editor_metadata.EVENTS} />} />
              <Route path="settings" element={<Settings context={editor_metadata.PROFILE_EDIT} />} />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
