import React, { useState } from 'react';
import '@mdxeditor/editor/style.css';
import { MDXEditor } from '@mdxeditor/editor';
import { headingsPlugin } from '@mdxeditor/editor/plugins/headings';
import { UndoRedo } from '@mdxeditor/editor/plugins/toolbar/components/UndoRedo';
import { BoldItalicUnderlineToggles } from '@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles';
import { CodeToggle } from '@mdxeditor/editor/plugins/toolbar/components/CodeToggle';
import { toolbarPlugin } from '@mdxeditor/editor/plugins/toolbar';

const MdxEditorComponent = ({ content, setContent }) => {
    return (
      <div>
        <MDXEditor markdown={content}
        onChange={(value) => setContent(value)}
          plugins={[toolbarPlugin({
            toolbarContents: () => (
              <>
                <UndoRedo />
                <BoldItalicUnderlineToggles />
                <CodeToggle />
              </>
            ),
          })]}
        />
      </div>
    );
  };

export default MdxEditorComponent;