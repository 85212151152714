import React, {useState} from 'react';
import { IconMap } from '../../config/IconMap';

export default function FormEditDetailView({formTitle, setFormTitle, formDescription, setFormDescription, }) {

    // Event handler to update the title state
    const handleTitleChange = (e) => {
        setFormTitle(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setFormDescription(e.target.value);
    };


    return (
        <div className="px-6 py-6">
            <form>
                <div>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8">

                        {/* New App Title input */}
                        <div className="col-span-full">
                            <label htmlFor="app-title" className="block text-sm font-medium leading-6 text-gray-900">
                                Form title
                            </label>
                            <p className="mt-3 text-sm leading-6 text-gray-600">Enter the title for your form (maximum 15 characters).</p>
                            <div className="mt-2">
                                <input
                                    id="app-title"
                                    name="app-title"
                                    type="text"
                                    maxLength={15}
                                    value={formTitle}
                                    onChange={handleTitleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    required
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                Form description
                            </label>
                            <p className="mt-3 text-sm leading-6 text-gray-600">This is the description line that appears in widgets and lists.</p>
                            <div className="mt-2">
                                <textarea
                                    id="description"
                                    name="description"
                                    rows={2}
                                    maxLength={20}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={formDescription}
                                    onChange={handleDescriptionChange}
                                    required
                                />
                                <p className="text-xs italic mt-4">Maximum 20 characters</p>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    )
}
