import React, { useState } from 'react';

export default function FormSettings() {
  const [actionType, setActionType] = useState('');
  const [changesMade, setChangesMade] = useState(false);
  const [restURL, setRestURL] = useState('');
  const [email, setEmail] = useState('');

  const handleActionChange = (e) => {
    setActionType(e.target.value);
    setChangesMade(true);
  };

  const handleInputChange = () => {
    setChangesMade(true);
  };

  return (
    <form>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Submissions</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Configure the actions triggered by user form submissions, such as sending data via REST API or email.
          </p>
          <div className="mt-10 space-y-10 w-1/2">
            <fieldset>
              <legend className="text-sm font-semibold leading-6 text-gray-900">Submission action</legend>
              <div className="mt-6 space-y-6">
                <div className="flex items-center gap-x-3">
                  <input
                    id="push-everything"
                    name="push-notifications"
                    type="radio"
                    value="REST"
                    checked={actionType === 'REST'}
                    onChange={handleActionChange}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                    Enable REST API with standard logging
                  </label>
                </div>
                <div className="flex items-center gap-x-3">
                  <input
                    id="push-email"
                    name="push-notifications"
                    type="radio"
                    value="EMAIL"
                    checked={actionType === 'EMAIL'}
                    onChange={handleActionChange}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                    Get submissions via email receipt
                  </label>
                </div>
                {actionType === 'REST' && (
                  <div>
                    <label htmlFor="restURL" className="block text-sm font-medium leading-6 text-gray-900">
                      REST URL Endpoint:
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="restURL"
                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder=""
                        onChange={(e) => {
                          setRestURL(e.target.value);
                          handleInputChange();
                        }}
                      />
                    </div>
                  </div>
                )}
                {actionType === 'EMAIL' && (
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Email address:
                    </label>
                    <div className="mt-2">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="example@mail.com"
                      />
                    </div>
                  </div>
                )}
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
          Cancel
        </button>
        <button
          type="submit"
          disabled={!changesMade}
          className={`rounded-md px-3 py-2 text-sm font-semibold ${changesMade ? 'bg-indigo-600 text-white hover:bg-indigo-500' : 'bg-gray-300 text-gray-600'
            }`}
        >
          Save
        </button>
      </div>
    </form>
  );
}
