import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

/* Tools */
import Skeleton from 'react-loading-skeleton';
import { IconMap } from '../config/IconMap';

/* Components */
import SaasMenu from '../components/SaasMenu/SaasMenu';

export default function Hub({ previewMode, previewData }) {
  // User agent checks
  // const displaySaaSMenu = userAgent.indexOf('ourapp_');

  // States
  const { uid } = useParams();
  const [metaData, setMetaData] = useState(null); // State to store configuration data
  const [styleGuide, setStyleGuide] = useState(null);

  // Local storage objects 
  const recentActivity = localStorage.getItem(`recentActivity-${uid}`);
  const recentActivityJSON = JSON.parse(recentActivity);

  // Transitions
  const [fadeIn, setFadeIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Use effect to call App config if not in preview editor
  useEffect(() => {
    // Fetch configuration data from the API when previewMode is false
    if (!previewMode) {
      fetch(`/api/get-app/${uid}`) // Replace :ID with the actual app ID
        .then((response) => response.json())
        .then((data) => {
          setMetaData(data);
          setIsLoading(false);
          if (data.style_guide) {
            setStyleGuide(data.style_guide);
          }
        })
        .catch((error) => {
          console.error('Error fetching configuration data:', error);
          setIsLoading(true);
        });
    } else if (previewData && previewMode) {
        setIsLoading(false);
    }
  }, [uid]);

  // load preview data into state if in preview mode
  useEffect(() => {
    if (previewMode && previewData) {
      setMetaData(previewData);
      setIsLoading(false);
      if (previewData.style_guide) {
        setStyleGuide(previewData.style_guide);
      } 
    } 
  }, [previewMode, previewData]);

  useEffect(() => {
    if (!isLoading) {
      setFadeIn(true);
    }
  }, [isLoading]);

  return (
    <>
      <div className="min-h-full">

        <div className={"pb-32 headerContainer bg-gray-400"}
          style={{ backgroundColor: styleGuide?.primary }}>
          <SaasMenu previewMode={previewMode} />
        </div>

        <main className="-mt-36 pb-6">
          <div className="mx-auto max-w-3xl lg:max-w-5xl">
            {!metaData ? (
              <div className="mt-4 mx-4 overflow-hidden rounded-lg">
                <Skeleton className="my-2" count={1} height={40} width={250} />
                <Skeleton className="my-2" count={1} height={40} width={150} />
              </div>
            ) : (
                <>
                  <h1 className={`text-2xl font-bold tracking-tight text-white px-4 faded ${fadeIn && 'visible'}`}>{metaData?.title}</h1>
                  <p className={`text-base text-white px-4 faded ${fadeIn && 'visible'}`}>{metaData?.description}</p>
                </>
              )
            }

            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8 py-6">

              {/* Left column - dynamic content */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-2">

                {/* Tiles using JSON */}
                {!metaData ? (
                  <section className="mt-4 mx-4 overflow-hidden rounded-lg" >
                    <Skeleton className="my-2" count={1} height={40} width={150} />
                    <Skeleton className="rounded-lg mt-2 mb-4" count={2} height={100} />
                    <Skeleton className="mb-2 mt-4" count={1} height={40} width={150} />
                    <Skeleton className="rounded-lg my-2" count={2} height={100} />
                  </section>
                ) : (previewMode ? previewData?.app_config : metaData?.app_config)?.map((tile, index) => (
                  <section key={index} className="mt-4" aria-labelledby={`section-${index + 1}-title`}>
                    <h2 className="sr-only" id={`section-${index + 1}-title`}>
                      {tile.display_title}
                    </h2>
                    <p className={`text-base tracking-tight cardHeader px-4 faded ${fadeIn && 'visible'}`}>{tile.display_title}</p>
                    {tile.type === 'action' && (
                      <div className={`mt-4 overflow-hidden rounded-lg bg-white shadow mx-4 faded ${fadeIn && 'visible'}`}>
                        <ul>
                          {tile.schema.map((object, index) => (
                            <Link key={index} to={!previewMode ? object.to : undefined} className="group block" target={object.option === 'external' ? "_blank" : undefined}>
                              <li>
                                <div className="flex items-center px-3 py-3">
                                  <div className="flex min-w-0 flex-1 items-center">
                                    <div className="min-w-0 flex-1 md:gap-4">
                                      <div>
                                        <p className="truncate text-sm font-medium text-gray-900">{object.name}</p>
                                        <p className="mt-2 flex items-center text-sm text-gray-500">
                                          <span className="truncate">{object.description}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    {object.option === 'external' && (
                                      React.createElement(IconMap['ArrowTopRightOnSquareIcon'], { className: 'h-5 w-5 text-gray-400 group-hover:text-gray-700', 'aria-hidden': 'true' })
                                    )}
                                    {object.option === 'internal' && (
                                      React.createElement(IconMap['ChevronRightIcon'], { className: 'h-5 w-5 text-gray-400 group-hover:text-gray-700', 'aria-hidden': 'true' })
                                    )}
                                  </div>
                                </div>
                              </li>
                            </Link>
                          ))}
                        </ul>
                      </div>
                    )}
                    {tile.type === 'gallery' && (
                      <div className="relative mt-4">
                        <div className="relative w-full overflow-x-auto no-scrollbar">
                          <ul
                            id="gallery"
                            role="list"
                            className="inline-flex space-x-8 lg:grid lg:grid-cols-3 lg:gap-x-2 lg:space-x-0"
                          >
                            {tile.schema.map((object, index) => (
                              <li key={index} className={`inline-flex w-60 flex-col text-center lg:w-auto faded ${fadeIn && 'visible'}`} >
                                <div className="group relative">
                                  <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200">
                                    <img
                                      src={object.imageSrc}
                                      alt="Alt"
                                      className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <h3 className="mt-1 text-gray-900">
                                      <a href="#">
                                        <span className="absolute inset-0" />
                                        {object.name}
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </section>
                ))}
              </div>

              {/* 
              {
                "config": [
                  {
                    "id": "1",
                    "type": "submission",
                    "date": "2023-01-01"
                  },
                  {
                    "id": "2",
                    "type": "other",
                    "date": "2023-01-02"
                  }
                ]
              }
              */}
              
              {!previewMode && (
                <div className="grid grid-cols-1 gap-4">
                  <section className="mt-4" aria-labelledby="section-2-title">
                    {recentActivity && (
                      <>
                        <p className={`tracking-tight text-base sm:text-black lg:text-white cardHeader-black mx-4 faded ${fadeIn && 'visible'}`}>Recent activity</p>
                        <h2 className="sr-only" id="section-2-title">
                          Your recent activity sorted by latest activities
                        </h2>
                        <div className={`mt-4 mx-4 overflow-hidden rounded-lg bg-white shadow faded ${fadeIn && 'visible'}`}>
                          {/* Check if recent activity data is available */}
                          {recentActivity && recentActivityJSON && (
                            <ul>
                              {recentActivityJSON.config.map((activity, index) => (
                                <Link key={index} to={"/form/" + activity.id}>
                                  <li className="group block">
                                    <div className="flex items-center px-3 py-3">
                                      <div className="flex min-w-0 flex-1 items-center">
                                        <div className="min-w-0 flex-1 md:gap-4">
                                          <div>
                                            <p className="truncate text-sm font-medium text-gray-900">
                                              {activity.type === 'submission' ? 'Form submission' : 'Other'}
                                            </p>
                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                              <span className="truncate">{activity.date}</span>
                                            </p>
                                          </div>
                                        </div>
                                        {(
                                          React.createElement(IconMap['ChevronRightIcon'], { className: 'h-5 w-5 text-gray-400 group-hover:text-gray-700', 'aria-hidden': 'true' })
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                </Link>
                              ))}
                            </ul>
                          )}
                        </div>
                      </>
                    )}
                  </section>
                </div>
              )}
            </div>
          </div>


        </main>
      </div>
      {!previewMode && <div className="text-gray-500 text-sm text-center fixed bottom-0 bg-gray-100 w-full p-4 mt-4"><p>Powered by <Link to="https://ourapp.studio" target="_blank" className='underline text-indigo-600'>ourapp.studio</Link></p></div>}
      </>
  )
}
