import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

// Helper function to combine CSS class names
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

// Component to display color squares
const ColorSquares = ({ primary, secondary, accent }) => (
    <div className="flex items-center space-x-2">
        <span className="h-8 w-8 rounded-md" style={{ backgroundColor: primary }}></span>
        <span className="h-8 w-8 rounded-md" style={{ backgroundColor: secondary }}></span>
        <span className="h-8 w-8 rounded-md" style={{ backgroundColor: accent }}></span>
    </div>
);

// Main StyleGuide component
function StyleGuide({ onStyleGuideChange, currentStyleGuide }) {

    // State for storing style guides and UI control
    const [styleGuides, setStyleGuides] = useState([]);
    const [selectedStyleGuide, setSelectedStyleGuide] = useState('');
    const [styleGuidesLoaded, setStyleGuidesLoaded] = useState(false);

    useEffect(() => {
        const authToken = Cookies.get('auth_token');
        fetch('/api/get-styles', {
            headers: { 'Authorization': `Token ${authToken}` },
        })
        .then(response => response.json())
        .then(data => {
            setStyleGuides(data);
            setStyleGuidesLoaded(true);
        })
        .catch(error => console.error('Error fetching style guides:', error));
    }, []); 

    // Effect for setting selected style guide
    useEffect(() => {
        if (styleGuidesLoaded) {
            if (currentStyleGuide && currentStyleGuide.uid) {
                setSelectedStyleGuide(currentStyleGuide.uid);
            } else if (styleGuides.length > 0) {
                setSelectedStyleGuide(styleGuides[0].uid);
            }
        }
    }, [styleGuides, currentStyleGuide, styleGuidesLoaded]);

    const handleStyleGuideSelection = (selectedUID) => {
        if (selectedUID !== selectedStyleGuide) {
            setSelectedStyleGuide(selectedUID);
            const selectedSG = styleGuides.find(sg => sg.uid === selectedUID);
            if (selectedSG) {
                onStyleGuideChange(selectedSG);
            }
        }
    };

    return (
        <div className='px-6 py-6'>
            {styleGuides.length > 0 && (
                <RadioGroup value={selectedStyleGuide} onChange={handleStyleGuideSelection}>
                    <RadioGroup.Label className="text-sm font-medium text-gray-900">Select a style guide</RadioGroup.Label>
                    <div className="mt-2 grid gap-y-4">
                        {styleGuides.map(sg => (
                            <RadioGroup.Option 
                            key={sg.uid} 
                            value={sg.uid} 
                            className={({ checked }) => 
                                classNames(
                                    checked ? 'border-indigo-500' : 'border-gray-300',
                                    'flex cursor-pointer rounded-lg border-2 p-4'
                                )
                            }
                        >
                                {({ checked }) => (
                                    <>
                                        <div className="flex justify-between items-center w-full">
                                            <div className="flex flex-col">
                                                <span className="text-sm font-medium text-gray-900">{sg.title}</span>
                                                <span className="text-sm text-gray-500">{sg.description}</span>
                                            </div>
                                            <ColorSquares primary={sg.primary} secondary={sg.secondary} accent={sg.accent} />
                                        </div>
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            )}
        </div>
    );
}

export default StyleGuide;
