import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IconMap } from '../config/IconMap';

import SaasMenu from '../components/SaasMenu/SaasMenu';
import Search from '../components/Search/Search';

export default function Find() {

  // User agent checks
  const [externalLinkClicked, setExternalLinkClicked] = useState(false);

  const app_config = [
    {
      "position": "1",
      "display_title": "Find",
      "type": "action",
      "schema": [
        {
          "name": "Scan",
          "description": "Scan QR code generated by ourapp",
          "to": "/app/form/",
          "option": "internal"
        }
      ]
    }
  ]

  // Function to get greeting based on time of day
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) {
      return 'Good morning';
    } else if (hour < 18) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  };
  const greeting = getGreeting();

  // TO DO: Check if our user is coming via the native app and if go 
  useEffect(() => {
    const isNativeApp = navigator.userAgent.indexOf('ourapp__saas') > -1;
    if (isNativeApp) {
      const statusBarColor = externalLinkClicked ? 'white' : '4f46e5';
      window.gonative.statusbar.set({
        style: 'dark',
        color: statusBarColor,
      });
    }
  }, [externalLinkClicked]);

  // External link behaviour 
  const handleExternalLinkClick = () => {
    setExternalLinkClicked(true);
  };

  return (
    <>
      { }
      <div className="min-h-full">

        <div
          className={"find_feature pb-32 headerContainer"}>
          <SaasMenu />
        </div>

        <main className="-mt-36 pb-6">
          <div className="mx-auto max-w-3xl lg:max-w-5xl">

            <h1 className="text-2xl font-bold tracking-tight text-white px-4">{greeting}</h1>
            <p className="text-sm text-white px-4"></p>

            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8 py-6">

              {/* Left column - dynamic content */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-2">

                <Search />

                {/* Tiles using JSON */}
                {app_config?.map((tile, index) => (
                  <section key={index} className="mt-4" aria-labelledby={`section-${index + 1}-title`}>
                    <h2 className="sr-only" id={`section-${index + 1}-title`}>
                      {tile.display_title}
                    </h2>
                    <p className="tracking-tight cardHeader px-4">{tile.display_title}</p>
                    {tile.type === 'action' && (
                      <div className="mt-4 overflow-hidden rounded-lg bg-white shadow mx-4">
                        <ul>
                          {tile.schema.map((object) => (
                            <Link to={object.to} className="group block" target={object.option === 'external' ? "_blank" : undefined} onClick={object.option === 'external' ? handleExternalLinkClick : undefined}>
                              <li key={object.id}>
                                <div className="flex items-center px-3 py-3">
                                  <div className="flex min-w-0 flex-1 items-center">
                                    <div className="min-w-0 flex-1 md:gap-4">
                                      <div>
                                        <p className="truncate text-sm font-medium text-gray-900">{object.name}</p>
                                        <p className="mt-2 flex items-center text-sm text-gray-500">
                                          <span className="truncate">{object.description}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    {object.option === 'external' && (
                                      React.createElement(IconMap['ArrowTopRightOnSquareIcon'], { className: 'h-5 w-5 text-gray-400 group-hover:text-gray-700', 'aria-hidden': 'true' })
                                    )}
                                    {object.option === 'internal' && (
                                      React.createElement(IconMap['ChevronRightIcon'], { className: 'h-5 w-5 text-gray-400 group-hover:text-gray-700', 'aria-hidden': 'true' })
                                    )}
                                  </div>
                                </div>
                              </li>
                            </Link>
                          ))}
                        </ul>
                      </div>
                    )}
                    {tile.type === 'gallery' && (
                      <div className="relative mt-4">
                        <div className="relative w-full overflow-x-auto no-scrollbar">
                          <ul
                            id="gallery"
                            role="list"
                            className="inline-flex space-x-8 lg:grid lg:grid-cols-3 lg:gap-x-2 lg:space-x-0"
                          >
                            {tile.schema.map((object) => (
                              <li key={object.id} className="inline-flex w-60 flex-col text-center lg:w-auto">
                                <div className="group relative">
                                  <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200">
                                    <img
                                      src={object.imageSrc}
                                      alt="Alt"
                                      className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <h3 className="mt-1 text-gray-900">
                                      <a href="#">
                                        <span className="absolute inset-0" />
                                        {object.name}
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </section>
                ))}
              </div>

              <div className="grid grid-cols-1 gap-4">
                <section className="mt-4" aria-labelledby="section-2-title">
                  <p className="tracking-tight sm:text-black lg:text-white cardHeader-black mx-4">Recent activity</p>
                  <h2 className="sr-only" id="section-2-title">
                    Your recent activity sorted by latest activities
                  </h2>
                  <div className="mt-4 mx-4 overflow-hidden rounded-lg bg-white shadow">
                    {/* Stacked list */}
                    <ul>
                      <li key="id">
                        <a href="#" className="group block">
                          <div className="flex items-center px-3 py-3">
                            <div className="flex min-w-0 flex-1 items-center">
                              <div className="min-w-0 flex-1 md:gap-4">
                                <div>
                                  <p className="truncate text-sm font-medium text-gray-900">Recent activity</p>
                                  <p className="mt-2 flex items-center text-sm text-gray-500">
                                    <span className="truncate">Description</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div>
                              {React.createElement(IconMap['ChevronRightIcon'], { className: 'h-5 w-5 text-gray-400 group-hover:text-gray-700', 'aria-hidden': 'true' })}
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </section>
              </div>

            </div>
          </div>

        </main>
      </div>
    </>
  )
}
