// ReactFlowEditor.jsx
import React, { useCallback } from 'react';
import ReactFlow, { MiniMap, Controls, Background } from 'reactflow';
import 'reactflow/dist/style.css';
import CustomNode from './CustomNode'; // Import the CustomNode component
import html2canvas from 'html2canvas';

const ReactFlowEditor = ({ elements, onNodesChange, onEdgesChange }) => {
  const handleDownload = useCallback(() => {
    const flowContainer = document.querySelector('.react-flow');
    
    if (flowContainer) {
      html2canvas(flowContainer, { scale: 2 }).then((canvas) => {
        const img = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = img;
        link.download = 'react-flow-diagram.png';
        link.click();
      });
    }
  }, []);

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      {/* Uncomment to enable the download button */}
      {/*
        <button
          onClick={handleDownload}
          className="bg-indigo-600 text-white p-2 rounded"
        >
          Download Image
        </button>
      */}
      <ReactFlow
        elements={elements}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={{ custom: CustomNode }} // Pass custom node types
        style={{ background: '#f5f5f5' }}
        className="react-flow"
      >
        <MiniMap nodeColor={(node) => node.style?.background || '#ffffff'} />
        <Controls />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
  );
};

export default ReactFlowEditor;
