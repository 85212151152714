// CustomNode.jsx
import React from 'react';

const CustomNode = ({ data }) => {
  return (
    <div className="custom-node" style={{ padding: '10px', borderRadius: '5px', background: '#fff', border: '1px solid #ddd' }}>
      <h3 className="text-lg font-bold">{data.title}</h3>
      <p>{data.description}</p>
    </div>
  );
};

export default CustomNode;
