import React, { useState } from 'react';
import { IconMap } from '../../config/IconMap';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Tabs({ tabs, activeTab, onTabChange, togglePreviewPanel, isPreviewPanelVisible}) {

  return (
    <div>
      <div className="sm:hidden px-6 py-6">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="">
          <nav className="px-4 -mb-px flex justify-between bg-white border-b border-gray-200" aria-label="Tabs">
            <div className="flex">
            {tabs.map((tab, index) => (
              <a
                key={tab.name}
                href="#"
                onClick={() => onTabChange(index)}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 mx-2 text-sm font-medium'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.current ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                      'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </a>
            ))}
            </div>
            {/* Play */}
            <button onClick={togglePreviewPanel} className="hidden xl:block rounded-md border-0 my-2 px-2 text-indigo-600 bg-white ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 hover:bg-gray-50">
              {
                isPreviewPanelVisible ? (
                  <>
                    {React.createElement(IconMap['XMarkIcon'], { className: 'h-6 w-6', 'aria-hidden': 'true' })}
                  </>
                ) : (
                  <>
                    {React.createElement(IconMap['PlayIcon'], { className: 'h-6 w-6', 'aria-hidden': 'true' })}
                  </>
                )
              }
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
