import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie'; 
import axios from 'axios';
import UpgradeAccount from '../components/UpgradeAccount/UpgradeAccount';
import ProductGrid from '../components/ProductGrid/ProductGrid';
import { editor_metadata } from '../config/config';

export default function VendorProfile() {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = Cookies.get('auth_token');
                if (!token) {
                    throw new Error('No authentication token found');
                }                
                const response = await axios.get('/api/vendor-profile/', {
                    headers: {
                        Authorization: `Token ${token}`,  
                    },
                });

                setProfile(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching profile:", error);
                setLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    if (loading) return <p>Loading...</p>;

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">
                    <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                        {/* Conditionally render based on vendor level */}
                        {profile.show_upgrade_form ? (
                            <UpgradeAccount context={editor_metadata.UPGRADE_ACCOUNT} />
                        ) : (
                            <ProductGrid context={editor_metadata.PRODUCT_EDIT} />
                        )}
                    </div>
                </div>
            </main>
            <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-l border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block">
            </aside>
        </>
    );
}
