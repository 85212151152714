import React, { useState, useRef } from 'react';
import Hub from '../../pages/Hub';
import { IconMap } from '../../config/IconMap';
import Tooltip from './Tooltip';

const PhoneObject = ({ app_metadata, appConfig, onAppConfigUpdate, onAddItem, onDeleteItem, scale, pan, setPan, handleWheel }) => {

  const [isPhoneDraggable, setIsPhoneDraggable] = useState(false);  // false by default
  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = () => setShowTooltip(!showTooltip);
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(null);
  const phoneRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const dx = e.clientX - startPosition.x;
    const dy = e.clientY - startPosition.y;
    setPan({ x: pan.x + dx, y: pan.y + dy }); // Update the pan state
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setStartPosition(null);
  };

  const sideIcon = {
    position: 'absolute',
    top: '50%', // Center vertically
    right: '-5rem', // Adjust the left position as needed
    transform: 'translate(-50%, -50%)', // Center horizontally
    cursor: 'pointer',
  };

  const positionTools = {
    position: 'absolute',
    bottom: '-10%',
    cursor: 'pointer',
  }

  const sideMenu = {
    zIndex: '99',
    position: 'absolute',
    top: '70%',
    transform: 'translate(-50%, -50%)', // Center horizontally
    cursor: 'pointer',
    minWidth: '20rem',
    minHeight: '10rem'
  }

  const topText = {
    position: 'absolute',
    top: '-2rem',
    left: '10rem',
    transform: 'translate(-50%, -50%)', // Center horizontally
    cursor: 'pointer',
  }

  return (
    <>
      <div
        className="canvas mockup-phone border-primary shadow hover:shadow-md"
        style={{
          transform: `scale(${scale}) translate(${pan.x}px, ${pan.y}px)`,
        }}
        ref={phoneRef}
        onWheel={handleWheel}
        onMouseDown={isPhoneDraggable ? handleMouseDown : null}  // Apply conditionally
        onMouseMove={isPhoneDraggable ? handleMouseMove : null}  // Apply conditionally
        onMouseUp={isPhoneDraggable ? handleMouseUp : null}  // Apply conditionally
      >
        <div className="text-sm text-gray-600" style={topText}>App index</div>
        <div className="camera"></div>

        <div className="display bg-gray-50">
          <div className="content artboard phone">
            <Hub previewMode={true} previewData={app_metadata} />
          </div>
        </div>

        {/* Toggle Icon */}
        <div
          className={`plus-icon border border-gray-300 ${showTooltip ? 'bg-gray-200 text-gray-700' : 'text-gray-500 hover:text-gray-700 hover:bg-gray-200'}`}
          style={sideIcon}
          onClick={toggleTooltip}
        >
          {React.createElement(
            showTooltip ? IconMap['XMarkIcon'] : IconMap['SquaresPlusIcon'],
            { className: 'block h-6 w-6 m-1', 'aria-hidden': 'true' }
          )}
        </div>

        {/* Tooltip that appears on click */}
        {showTooltip && (
          <Tooltip
            appConfig={appConfig}
            onClose={toggleTooltip}
            onUpdateAppConfig={onAppConfigUpdate}
            onAddItem={onAddItem}
            onDeleteItem={onDeleteItem}
            style={sideMenu}
          />
        )}

        {/* Position phone icon */}
        <div
          className="position-tools"
          style={positionTools}
        >

          {/* Edit button 
            <button
              className={`m-1 border border-gray-300 ${isPhoneDraggable ? 'bg-gray-200 text-gray-700' : ''}`}
              onClick={() => setIsPhoneDraggable(!isPhoneDraggable)}
            >
              {React.createElement(
                IconMap['CursorArrowRaysIcon'],
                {
                  className: `block h-6 w-6 m-1 text-gray-500 ${isPhoneDraggable ? 'hover:text-gray-700 hover:bg-gray-200' : 'text-gray-500'}`,
                  'aria-hidden': 'true'
                }
              )}
            </button>
          */}

          {/* Move button */}
          <button
            className={`m-1 border border-gray-300 ${isPhoneDraggable ? 'bg-gray-200 text-gray-700' : ''}`}
            onClick={() => setIsPhoneDraggable(!isPhoneDraggable)}
          >
            {React.createElement(
              IconMap['ArrowsPointingOutIcon'],
              {
                className: `block h-6 w-6 m-1 text-gray-500 ${isPhoneDraggable ? 'hover:text-gray-700 hover:bg-gray-200' : 'text-gray-500'}`,
                'aria-hidden': 'true'
              }
            )}
          </button>

        </div>

      </div>
    </>
  );
};

export default PhoneObject;
