import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

export default function BetaBanner() {
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <>
            <div className={`hidden md:flex pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8 fade-in`}>
                <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
                    <p className="text-sm leading-6 text-white">
                        <a href="https://ourapp.io/help/roadmap" target='_blank'>
                            <strong className="font-semibold">Get excited: </strong>
                            Read more about the new features we are releasing for this page &nbsp;<span aria-hidden="true">&rarr;</span>
                        </a>
                    </p>
                    <div className="flex flex-1 justify-end">
                        <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]" onClick={handleClose}>
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
