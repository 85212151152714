import React, { useState, useEffect } from 'react';

export default function FormWizard({formSchema, previewMode = false}) {

  // State to track current step and form data
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState(formSchema.map(() => ({})));
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const hasManySteps = formSchema.length >= 4;

  // Function to handle moving to the next step
  const handleNextStep = () => {
    if (currentStep < formSchema.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  // Function to check if all answers on the current page are provided
  const handleInputChange = (e, questionIndex) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = [...prevData];
      updatedData[currentStep] = {
        ...updatedData[currentStep],
        [name]: value,
      };
      return updatedData;
    });
  };

  // Function to check if all answers on the current page are provided
  const checkAnswers = () => {
    const currentPageQuestions = formSchema[currentStep].questions;
    const currentPageData = formData[currentStep] || {};

    // Check if all questions on the current page have answers in currentPageData
    const allQuestionsAnswered = currentPageQuestions.every((question, questionIndex) => {
      return currentPageData[`question-${questionIndex}`] !== undefined;
    });

    return allQuestionsAnswered;
  };

  // Update the "Next" button state when the form data changes
  useEffect(() => {
    setIsNextDisabled(!checkAnswers());
    // Debugging: Log the currentStep and isNextDisabled whenever they change
    // console.log('currentStep:', currentStep);
    // console.log('isNextDisabled:', isNextDisabled);
  }, [formData, currentStep]);

  // Calculate the progress percentage
  const progressPercentage = ((currentStep + 1) / formSchema.length) * 100;

  return (
    <form className='md:px-4'>
      {/* Conditional rendering based on the number of steps */}
      {hasManySteps ? (
        <div className={`${!previewMode ? "md:hidden px-4" : "px-4"}`}>
          <p className="mb-2 text-sm text-white">Page {currentStep + 1} of {formSchema.length}</p>
          <div className="overflow-hidden rounded-full bg-gray-500 ">
            <div className="h-2 rounded-full bg-green-500" style={{ width: `${progressPercentage}%` }} />
          </div>
        </div>
      ) : (
        <div className="mx-4">
            <p className="text-base text-white">
              Page {currentStep + 1} of {formSchema.length}
            </p>
          </div>
      )}

      <div className="mt-6 overflow-hidden rounded-lg bg-white shadow px-6 pb-6 pt-4">
        <p className="text-base font-medium leading-6 text-gray-900 mt-3">{formSchema[currentStep].title}</p>
        {formSchema[currentStep].questions.map((question, questionIndex) => (
          <div key={questionIndex} className="mt-6">
            <label
              htmlFor={`question-${questionIndex}`}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {question.label}
            </label>
            {/* Validation */}
            {formData[currentStep]?.[`question-${questionIndex}`] === undefined && (
              <p className="mt-2 text-sm text-red-600">{question.message}</p>
            )}
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  type="text"
                  name={`question-${questionIndex}`}
                  id={`question-${questionIndex}`}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder=""
                  value={formData[currentStep]?.[`question-${questionIndex}`] || ''}
                  onChange={(e) => handleInputChange(e, questionIndex)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <button
        onClick={handleNextStep}
        disabled={isNextDisabled || currentStep === formSchema.length - 1}
        className="bg-gray-400 text-white py-3 mt-6 px-6 shadow-lg hover:bg-gray-500 transition duration-300 block w-3/4 mx-auto text-center rounded-lg"
        type="button" // Change the type to "button"
      >
        {currentStep === formSchema.length - 1 ? 'Submit' : 'Next'}
      </button>
    </form>
  );
}
