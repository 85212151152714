import React, { Fragment, useState } from 'react'
import Cookies from 'js-cookie';
import { Dialog, Transition } from '@headlessui/react'
import { IconMap } from '../../config/IconMap';

export default function CreateAside({ type, onClose, onCreate, isDialogOpen }) {

    const authToken = Cookies.get('auth_token');
    const [formData, setFormData] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length <= 20) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(null);  // Clear any previous error message

        // Validate the form data
        if (!formData.title || formData.title.trim() === '') {
            setErrorMessage('Asset name is required.');
            return;
        }

        let apiEndpoint, successMessage;
        switch (type) {
            case 'form':
                apiEndpoint = '/api/create-form';
                successMessage = 'New form created successfully.';
                break;
            case 'page':
                apiEndpoint = '/api/create-page'; // Adjust with your actual endpoint
                successMessage = 'New page created successfully.';
                break;
            case 'app':
            default:
                apiEndpoint = '/api/create-app';
                successMessage = 'New app created successfully.';
                break;
        }
        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${authToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                onCreate(data); // Pass the data to the parent component
                onClose(); // Close the dialog after successful creation
            } else {
                const errorData = await response.json();
                // Check if the error is related to the app cap
                if (response.status === 403 && errorData.error === 'app-limit-reached') {

                    setErrorMessage(
                        <>
                            You have reached the maximum number of apps allowed under your current plan. Please view{' '}
                            <a href="https://ourapp.studio/#pricing" target="_blank" rel="noopener noreferrer">
                                our website for more information
                            </a>.
                        </>
                    );
                } else {
                    setErrorMessage('Request failed. Please try again.');
                }
                console.error('Request failed with status:', response.status);
            }

        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
            console.error('Error:', error);
        }
    };

    return (
        <Transition.Root show={isDialogOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                    <form onSubmit={handleSubmit} className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                        {/* Conditional rendering based on type */}
                                        {(type === 'form' || type === 'page') && (
                                            <div className="flex-1">
                                                {/* Header */}
                                                <div className="bg-indigo-800 px-4 py-6 sm:px-6">
                                                    <div className="flex items-start justify-between space-x-3">
                                                        <div className="space-y-1">
                                                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                                                New {type}
                                                            </Dialog.Title>
                                                            <p className="text-sm text-gray-50">
                                                                Get started by filling in the information below to create your new {type}.
                                                            </p>
                                                        </div>
                                                        <div className="flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="relative text-gray-400 hover:text-gray-500"
                                                                onClick={onClose}
                                                            >
                                                                <span className="absolute -inset-2.5" />
                                                                <span className="sr-only">Close panel</span>
                                                                {React.createElement(
                                                                    IconMap['XMarkIcon'],
                                                                    { className: 'block h-6 w-6', 'aria-hidden': 'true' }
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Divider container */}
                                                <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                                                    {/* Project name */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="title"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                Form name
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <input
                                                                required
                                                                type="text"
                                                                name="title"
                                                                id="title"
                                                                maxLength={20}
                                                                value={formData.title}
                                                                onChange={handleChange}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <p className="text-xs italic mt-4">Maximum 20 characters</p>
                                                        </div>
                                                    </div>

                                                    {/* Project description */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="description"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                Description
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <textarea
                                                                id="description"
                                                                name="description"
                                                                value={formData.description}
                                                                onChange={handleChange}
                                                                required
                                                                rows={3}
                                                                maxLength={20}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <p className="text-xs italic mt-4">Maximum 20 characters</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {type === 'app' && (

                                            <div className="flex-1">
                                                {/* Header */}
                                                <div className="bg-indigo-800 px-4 py-6 sm:px-6">
                                                    <div className="flex items-start justify-between space-x-3">
                                                        <div className="space-y-1">
                                                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                                                New project
                                                            </Dialog.Title>
                                                            <p className="text-sm text-gray-50">
                                                                Get started by filling in the information below to create your new app.
                                                            </p>
                                                        </div>
                                                        <div className="flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="relative text-gray-400 hover:text-gray-500"
                                                                onClick={onClose}
                                                            >
                                                                <span className="absolute -inset-2.5" />
                                                                <span className="sr-only">Close panel</span>
                                                                {React.createElement(
                                                                    IconMap['XMarkIcon'],
                                                                    { className: 'block h-6 w-6', 'aria-hidden': 'true' }
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Divider container */}
                                                <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                                                    {/* Project name */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="title"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                App name
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <input
                                                                required
                                                                type="text"
                                                                name="title"
                                                                id="title"
                                                                maxLength={20}
                                                                value={formData.title}
                                                                onChange={handleChange}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <p className="text-xs italic mt-4">Maximum 20 characters</p>
                                                        </div>
                                                    </div>

                                                    {/* Project description */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="description"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                Description
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <textarea
                                                                id="description"
                                                                name="description"
                                                                value={formData.description}
                                                                onChange={handleChange}
                                                                required
                                                                rows={3}
                                                                maxLength={20}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <p className="text-xs italic mt-4">Maximum 20 characters</p>
                                                        </div>
                                                    </div>

                                                    {/* Privacy */}
                                                    <fieldset className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <legend className="sr-only">Privacy</legend>

                                                        <div className="text-sm font-medium leading-6 text-gray-900" aria-hidden="true">
                                                            Privacy
                                                        </div>

                                                        <div className="space-y-5 sm:col-span-2">
                                                            <div className="space-y-5 sm:mt-0">
                                                                <div className="relative flex items-start">
                                                                    <div className="absolute flex h-6 items-center">
                                                                        <input
                                                                            id="public-access"
                                                                            name="privacy"
                                                                            aria-describedby="public-access-description"
                                                                            type="radio"
                                                                            maxLength={20}
                                                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                            defaultChecked
                                                                        />
                                                                    </div>
                                                                    <div className="pl-7 text-sm leading-6">
                                                                        <label htmlFor="public-access" className="font-medium text-gray-900">
                                                                            Intended for customers (public)
                                                                        </label>
                                                                        <p id="public-access-description" className="text-gray-500">
                                                                            Public web users will be able to see your app and interact with your configured services.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                {/*
                                                                        <div className="relative flex items-start">
                                                                        <div className="absolute flex h-6 items-center">
                                                                            <input
                                                                            id="private-access"
                                                                            name="privacy"
                                                                            aria-describedby="private-access-description"
                                                                            type="radio"
                                                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                            />
                                                                        </div>
                                                                        <div className="pl-7 text-sm leading-6">
                                                                            <label htmlFor="private-access" className="font-medium text-gray-900">
                                                                            Intended for staff  (private)
                                                                            </label>
                                                                            <p id="private-access-description" className="text-gray-500">
                                                                            You can invite staff via the permissions menu of this app. Only your invited users will have access to your app.
                                                                            </p>
                                                                        </div>
                                                                        </div>
                                                                    */}
                                                            </div>

                                                            <hr className="border-gray-200" />
                                                            <div className="flex flex-col items-start space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
                                                                <div>
                                                                    <a
                                                                        href="#"
                                                                        className="group flex items-center space-x-2.5 text-sm text-gray-500 hover:text-gray-900"
                                                                    >
                                                                        {React.createElement(
                                                                            IconMap['QuestionMarkCircleIcon'],
                                                                            { className: 'h-5 w-5 text-gray-400 group-hover:text-gray-500', 'aria-hidden': 'true' }
                                                                        )}
                                                                        <span>Learn more about sharing</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>

                                                {errorMessage && (
                                                    <div className="p-4 m-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                                                        {errorMessage}
                                                    </div>
                                                )}

                                            </div>

                                        )}

                                        {/* Action buttons - all scenarios */}
                                        <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <div className="flex justify-end space-x-3">
                                                <button
                                                    type="button"
                                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                    onClick={onClose}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>

            </Dialog>
        </Transition.Root >
    );
}
