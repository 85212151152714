import React from 'react';

const Preview = ({ LayoutComponent, config, assetTitle, previewMode, isPreviewVisible }) => {
    if (!isPreviewVisible) return null;

    return (
        <div className="bg-gray-50 w-96 pt-6 overflow-y-auto sliding-panel sliding-panel-visible">
            <div className="canvas mockup-phone border-primary shadow hover:shadow-md" style={{ margin: '1.25rem' }}>
                <div className="camera"></div>
                <div className="display bg-gray-50">
                    <div className="content artboard phone">
                        <LayoutComponent config={config} previewMode={previewMode} assetTitle={assetTitle} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preview;
