import {
    Squares2X2Icon,
    BellAlertIcon,
    Bars3BottomRightIcon,
    Bars3BottomLeftIcon,
    XMarkIcon,
    BuildingOffice2Icon,
    ChevronRightIcon,
    ChevronLeftIcon,
    ChevronDownIcon,
    ArrowLeftOnRectangleIcon,
    UserCircleIcon,
    UserIcon,
    RectangleGroupIcon,
    PaintBrushIcon,
    PencilSquareIcon,
    ArrowTopRightOnSquareIcon,
    BuildingStorefrontIcon,
    MagnifyingGlassIcon,
    DevicePhoneMobileIcon,
    SquaresPlusIcon,
    CheckCircleIcon,
    Bars2Icon,
    PencilIcon,
    Cog6ToothIcon,
    CursorArrowRaysIcon,
    RectangleStackIcon,
} from '@heroicons/react/24/outline';

import { 
    QuestionMarkCircleIcon,
    LinkIcon,
    PlusCircleIcon,
    MinusCircleIcon,
    PhotoIcon,
    PlusIcon,
    MinusIcon,
    ArrowsPointingOutIcon,
    ArrowUturnLeftIcon,
    PlayIcon
} from '@heroicons/react/20/solid'

export const IconMap = {
    Squares2X2Icon,
    BellAlertIcon,
    Bars3BottomRightIcon,
    Bars3BottomLeftIcon,
    XMarkIcon,
    BuildingOffice2Icon,
    ChevronRightIcon,
    ChevronLeftIcon,
    ArrowLeftOnRectangleIcon,
    ChevronDownIcon,
    UserCircleIcon,
    UserIcon,
    RectangleGroupIcon,
    PaintBrushIcon,
    PencilSquareIcon,
    ArrowTopRightOnSquareIcon,
    BuildingStorefrontIcon,
    MagnifyingGlassIcon,
    DevicePhoneMobileIcon, 
    QuestionMarkCircleIcon,
    LinkIcon,
    PlusCircleIcon,
    MinusCircleIcon,
    PlusIcon,
    MinusIcon,
    SquaresPlusIcon,
    PhotoIcon,
    CheckCircleIcon,
    Bars2Icon,
    PencilIcon,
    Cog6ToothIcon,
    ArrowsPointingOutIcon, 
    CursorArrowRaysIcon,
    RectangleStackIcon,
    ArrowUturnLeftIcon,
    PlayIcon
};
