import React, { useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { IconMap } from '../../config/IconMap';
import { Link } from 'react-router-dom';

import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'

export default function SaasMenu({ previewMode }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <header className="pb-10 pt-5">
      
      {/*
      {!previewMode && (
        <div className={`mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex justify-end`}>
          <button
            onClick={handleSidebarToggle}
            className="ml-2 relative inline-flex items-center justify-center rounded-md text-white p-2 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
            style={{ zIndex: 99 }}
          >
            <span className="absolute -inset-0.5" />
            <span className="sr-only">Toggle menu</span>
            {React.createElement(
              sidebarOpen ? IconMap['XMarkIcon'] : IconMap['Bars3BottomRightIcon'],
              { className: 'block h-6 w-6', 'aria-hidden': 'true' }
            )}
          </button>
          <Transition.Root show={sidebarOpen} as={React.Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-50"
              onClose={() => setSidebarOpen(false)}
            >
              <Transition.Child
                as={React.Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-900/80" />
              </Transition.Child>

              <div className="fixed inset-0 flex">
                <Transition.Child
                  as={React.Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full" // Change this line
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full" // Change this line
                >
                  <Dialog.Panel className="relative mx-auto flex w-full max-w-xs py-12"> 
                    
                    <RadioGroup value={selectedMailingLists} onChange={setSelectedMailingLists} className="w-full mt-12">
                      
                      <RadioGroup.Label className="text-3xl font-bold leading-tight tracking-tight text-white">
                        ai menu concept
                      </RadioGroup.Label>

                      <div className="mt-12 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                        {mailingLists.map((mailingList) => (
                          <RadioGroup.Option
                            key={mailingList.id}
                            value={mailingList}
                            className={({ active }) =>
                              classNames(
                                active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                                'relative flex cursor-pointer rounded-lg border p-4 shadow-sm focus:outline-none'
                              )
                            }
                          >
                            {({ checked, active }) => (
                              <>
                                <span className="flex flex-1">
                                  <span className="flex flex-col">
                                    <RadioGroup.Label as="span" className="block text-sm font-medium text-white">
                                      {mailingList.title}
                                    </RadioGroup.Label>
                                  </span>
                                </span>
                                <CheckCircleIcon
                                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                  aria-hidden="true"
                                />
                                <span
                                  className={classNames(
                                    checked ? 'border-indigo-600' : 'border-white',
                                    'border-3 pointer-events-none absolute -inset-px rounded-lg'
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                                  </RadioGroup>

                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )}
    */}
    </header>
  );
}
