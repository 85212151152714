import React, { useState } from 'react';
import { Navigate, Routes } from 'react-router-dom';

/* Templates */
import MobileMenu from '../../templates/MobileMenu/Index'
import DesktopSidebar from '../../templates/DesktopSidebar/Index';
import MobileHeader from '../../templates/MobileHeader/Index';

const PrivateRoute = ({ authenticated, redirectTo, children, onLogout, navigation }) => {

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const handleLogout = () => {
      onLogout();
  };

  if (!authenticated) {
    console.log('Not authenticated');
    return <Navigate to={redirectTo} />;
  }

  return (
    <div> 
      <MobileMenu isOpen={sidebarOpen} onLogout={handleLogout} onClose={() => setSidebarOpen(false)} navigation={navigation.navigation} externals={navigation.externals} />
      <DesktopSidebar navigation={navigation.navigation} externals={navigation.externals} onLogout={handleLogout} />
      <MobileHeader onMenuButtonClick={() => setSidebarOpen(true)} setSidebarOpen={setSidebarOpen} />
      <Routes>
        {children}
      </Routes>
    </div>
  );
};

export default PrivateRoute;