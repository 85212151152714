import React from 'react';
import { Disclosure } from '@headlessui/react';
import { IconMap } from '../../config/IconMap';

export default function SubRouteDisclosureButton({ previewMode }) {

  const handleButtonClick = () => {
      window.history.back(); // Navigate back in history
  };

  return (
    <header className="pb-10 pt-5">
      {previewMode !== true && (
        <div className={`mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex justify-start`}>
          <Disclosure>
            {({ open }) => (
              <>
                <button
                  onClick={handleButtonClick}
                  className={`relative inline-flex items-center justify-center rounded-md text-white py-2 pr-2 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 `}
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                    {React.createElement(
                      IconMap['ChevronLeftIcon'],
                      { className: 'block h-6 w-6', 'aria-hidden': 'true' }
                    )}
                  <span className="px-2">Back</span>
                </button>
              </>
            )}
          </Disclosure>
        </div>
      )}
    </header>
  );
}
