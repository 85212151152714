import { useState, useEffect } from 'react'
import Cookies from 'js-cookie';

/* Components */
import TableView from '../components/TableView/TableView';

export default function Events({ context }) {
    const [formData, setFormData] = useState([]);
    const authToken = Cookies.get('auth_token');

    {/* 
        useEffect(() => {
            // Make the GET request to fetch data using your convention
            const fetchData = async () => {
                try {
                    const response = await fetch('/api/forms', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Token ${authToken}`, // Replace with your authentication token
                        },
                    });
                    if (response.ok) {
                        const responseData = await response.json();
                        setFormData(responseData); // Update the data state with the fetched data
                    } else {
                        // Handle errors here
                        console.error('Request failed with status:', response.status);
                    }
                } catch (error) {
                    console.error('An error occurred:', error);
                }
            };
            fetchData(); // Call the function to make the request when the component mounts
        }, [context.API_ENDPOINT, authToken]); 
    */}

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">
                    <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                        {formData && (
                            <TableView context={context} data={formData} />
                        )}
                    </div>
                </div>
            </main>
            <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-l border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block">
            </aside>
        </>
    )
}