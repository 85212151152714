import { useState, useEffect } from 'react'
import Cookies from 'js-cookie';

/* Components */
import DetailRows from '../components/DetailRows/DetailRows';

export default function Settings({ context }) {
    
    const [userData, setUserData] = useState(null);
    const authToken = Cookies.get('auth_token');

    useEffect(() => {
        // Make the GET request to fetch user data from Django
        const fetchUserData = async () => {
            try {
                const response = await fetch('/api/profile', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${authToken}`, // Replace with your authentication token
                    },
                });

                if (response.ok) {
                    // Request was successful, parse the response as JSON
                    const data = await response.json();
                    setUserData(data); // Update the state with user data
                } else {
                    // Handle errors here
                    console.error('Request failed with status:', response.status);
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };
        fetchUserData(); 
    }, []);

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">
                    <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                        <h1 className="text-base font-semibold text-gray-900">{context.TITLE}</h1>
                        <p className="mt-1 text-sm text-gray-500">
                            {context.DESCRIPTION}
                        </p>     
                        <div className='grid grid-cols-1 gap-5 sm:grid-cols-1 xl:grid-cols-1'> 
                            {userData && (
                                <DetailRows context={context} userData={userData} />
                            )}
                        </div>
                    </div>
                </div>
            </main>

            <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-l border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block">
                {/* Secondary column (hidden on smaller screens)*/}
            </aside>
        </>
    )
}