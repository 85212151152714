// Canvas.jsx
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PhoneObject from './PhoneObject'; // Import the PhoneObject component
import Tooltip from './Tooltip';
import { IconMap } from '../../config/IconMap';

const Storyboard = ({ app_metadata, appConfig, onAppConfigUpdate, onAddItem, onDeleteItem }) => {

  const [scale, setScale] = useState(1);
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const canvasRef = useRef(null);

  const handleWheel = (e) => {
    e.preventDefault();
    const newScale = scale + e.deltaY * -0.01;
    if (newScale > 0.2 && newScale < 3) {
      setScale(newScale);
    }
  };


  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = () => setShowTooltip(!showTooltip);

  return (
    <div className="bg-gray-100">
      <div
        className="canvas-container hidden lg:block"
      >

        <div className="absolute w-full ml-2 mt-2 flex z-index-99">
                    
          <div className="p-2 text-sm">
            <Link to="../" className='flex hover:bg-gray-200 bg-gray-100 rounded-md p-2 border-2 border-gray-200'> 
              {React.createElement(IconMap['ArrowUturnLeftIcon'], { className: 'mr-2 h-4 w-4', 'aria-hidden': 'true' })} Back to apps
            </Link>
          </div>

          {/*<div className='mx-auto mt-4 text-sm'><strong>Editing</strong>: {app_metadata.title}</div>*/}

        </div>

        {/* Pass the necessary props to PhoneObject */}
        <PhoneObject
          app_metadata={app_metadata}
          appConfig={appConfig}
          onAppConfigUpdate={onAppConfigUpdate}
          onAddItem={onAddItem}
          onDeleteItem={onDeleteItem}
          scale={scale}
          pan={pan}
          setPan={setPan}
          handleWheel={handleWheel}
          canvasRef={canvasRef}
        />
      </div>
      <div className="block lg:hidden">
        <Tooltip
          appConfig={appConfig}
          onClose={toggleTooltip}
          onUpdateAppConfig={onAppConfigUpdate}
          onAddItem={onAddItem}
          onDeleteItem={onDeleteItem}
        />
      </div>
    </div>
  );
};

export default Storyboard;
