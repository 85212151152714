export const editor_metadata = {
    APP_EDIT: {
        TITLE: "Edit app",
        DESCRIPTION: "Drop and drag your app tiles. Learn more about configuring your app."
    },
    FORMS_NEW: {
        TITLE: "Create new form",
        REF: "forms",
        DESCRIPTION: "",
        ACTION: "save",
        SCHEMA: {
        }
    },
    FORM_EDIT: {
        TITLE: "Forms",
        REF: "forms",
        DESCRIPTION: "You can add, edit or delete business forms. Forms can connect to multiple apps.",
        ACTION: "Create",
        SCHEMA: {
            column_values: [{ id: 1, name: "Form name", description: "Description", created_date: "Date created", last_modified_date: "Last modified" }],
        },
        DELETE_ENABLED: true,
        DELETE_MESSAGING: {
            "title": "Are you sure?",
            "contextMsg": "Are you sure you want to delete this asset? This cannot be undone and may cause breaking changes in your app."
        },
        ERRORS: {
            message: "Forms that you create will appear here."
        }
    },
    PAGES_NEW: {
        TITLE: "Create new page",
        REF: "pages",
        DESCRIPTION: "Our page editor uses markdown (mdx) for a better experience. Learn how markdown can be used to format web content.",
        ACTION: "save",
        SCHEMA: {

        }
    },
    PAGE_EDIT: {
        TITLE: "Pages",
        REF: "pages",
        DESCRIPTION: "You can add, edit or delete business pages. Pages can connect to multiple apps.",
        ACTION: "Create",
        SCHEMA: {
            column_values: [{ id: 1, name: "Page name", description: "Description", created_date: "Date created", last_modified_date: "Last modified" }],
        },
        DELETE_ENABLED: true,
        DELETE_MESSAGING: {
            "title": "Are you sure?",
            "contextMsg": "Are you sure you want to delete this asset? This cannot be undone and may cause breaking changes in your app."
        },
        ERRORS: {
            message: "Pages that you create will appear here."
        }
    },
    EVENTS: {
        TITLE: "Events",
        REF: "events",
        DESCRIPTION: "Events are triggered when forms are submitted by users.",
        ACTION: "none",
        SCHEMA: {
            column_values: [{ id: 1, name: "Event", created_date: "Date created", last_modified_date: "Last modified" }],
        },
        DELETE_ENABLED: false,
        ERRORS: {
            message: "Create 1 or more forms to see event logs. Events are a way to check the health of your form integrations, and review recent customer interactions."
        }
    },
    PRODUCT_EDIT: {
        TITLE: "Products",
        REF: "products",
        DESCRIPTION: "You can add, edit or delete products you want to sell.",
        ACTION: "Create",
        SCHEMA: {
            values: [{
            }]
        }
    },
    PROFILE_EDIT: {
        TITLE: "Account information",
        DESCRIPTION: "This information is not shared publicly.",
        SCHEMA: {
            values: [
                {
                    field: 'Contact name',
                    type: 'concat',
                    targets: [
                        'first_name',
                        'last_name'
                    ],
                    placeholder: 'Loading...',
                    editable: true
                },
            ]
        }
    },
    QA_FORM: {
        TITLE: "General form exampke",
        STEPS: [
            {
                "step": 1,
                "title": "Details"
            },
            {
                "step": 2,
                "title": "Confirm"
            }
        ]
    },
    UPGRADE_ACCOUNT: {
        TITLE: "Let's setup your merchant account",
        CONTENT_1: "Stripe Connect is a powerful platform that allows us to onboard vendors and manage their payments easily. By integrating Stripe Connect, we enable our vendors to securely receive payouts for their sales directly to their bank accounts."
    },
    ONBOARD: {
        TITLE: "Let's start selling",
        STEPS: [
            {
                "step": 1,
                "title": "Details"
            },
            {
                "step": 2,
                "title": "Confirm"
            }
        ]
    }
}

export const editor_navigation = {
    "manage": {
        "navigation": [
            { "name": "Apps", "to": "/m", "icon": "Squares2X2Icon", "current": false },
            { "name": "Products", "to": "/m/products", "icon": "BuildingStorefrontIcon", "current": false },
            { "name": "Settings", "to": "/m/settings", "icon": "UserCircleIcon", "current": false }
        ],
        "externals": [
            { "id": 1, "name": "Help", "to": "https://ourapp.studio/docs", "initial": "H", "current": false }
        ]
    }
};

/**
export const editor_navigation = {
    "manage": {
        "navigation": [
            { "name": "Apps", "to": "/m", "icon": "Squares2X2Icon", "current": false },
            { "name": "Products", "to": "/m/products", "icon": "BuildingStorefrontIcon", "current": false },
            { "name": "Forms", "to": "/m/forms", "icon": "PencilSquareIcon", "current": false },
            { "name": "Pages", "to": "/m/pages", "icon": "RectangleGroupIcon", "current": false },
            { "name": "Events", "to": "/m/events", "icon": "RectangleStackIcon", "current": false },
            { "name": "Settings", "to": "/m/settings", "icon": "UserCircleIcon", "current": false }
        ],
        "externals": [
            { "id": 1, "name": "Help", "to": "https://ourapp.studio/docs", "initial": "H", "current": false }
        ]
    }
};
 */