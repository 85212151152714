import React, { useState, useEffect } from 'react';
import { IconMap } from '../../config/IconMap';
import Cookies from 'js-cookie';

export default function PageEditDetailView({
    displayAttributes, 
    setDisplayAttributes, 
    setActionEnabled, 
    setActionStr, 
    currentUid
}) {
    
    const authToken = Cookies.get('auth_token');
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const [isActionEnabled, setIsActionEnabled] = useState(displayAttributes.actionEnabled);
    const [selectedAction, setSelectedAction] = useState(displayAttributes.actionStr || '');

    const handleTitleChange = (e) => {
        setDisplayAttributes({ ...displayAttributes, pageTitle: e.target.value });
    };

    const handleDescriptionChange = (e) => {
        setDisplayAttributes({ ...displayAttributes, pageDescription: e.target.value });
    };

    useEffect(() => {
        setIsActionEnabled(displayAttributes.actionEnabled);
        if (!displayAttributes.actionEnabled) {
            setSelectedAction(''); // Reset selected action if action is not enabled
        }
    }, [displayAttributes.actionEnabled]);

    // Use displayAttributes directly instead of local state
    const handleActionEnabledCheckboxChange = (e) => {
        const enabled = e.target.checked;
        setActionEnabled(enabled); // Update parent's state directly

        if (!enabled) {
            setActionStr(''); // Clear action string if checkbox is unchecked
        }
    };

    const handleActionSelectChange = (e) => {
        const newActionStr = e.target.value || '';
        setActionStr(newActionStr); // Update parent's state directly
    };


    const fetchDropdownOptions = async () => {
        try {
            const response = await fetch('/api/get-assets', {
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // Filtering to exclude the current page's UID
            const filteredOptions = data.filter(option => option.uid !== currentUid).map(option => ({
                label: `${option.title} (${option.asset_type})`,
                value: option.uid
            }));

            setDropdownOptions(filteredOptions);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    useEffect(() => {
        fetchDropdownOptions();
    }, []);

    return (
        <div className="px-6 py-4">
            <form>
                <div>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8">
                        {/* Title and Description Inputs */}
                        
                        
                        {/* New App Title input */}
                        <div className="col-span-full">
                            <label htmlFor="page-title" className="block text-sm font-medium leading-6 text-gray-900">
                                Page title
                            </label>
                            <p className="mt-3 text-sm leading-6 text-gray-600">Enter the title for your page (maximum 15 characters).</p>
                            <div className="mt-2">
                                <input
                                    id="page-title"
                                    name="page-title"
                                    type="text"
                                    maxLength={20}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={displayAttributes.pageTitle}
                                    onChange={handleTitleChange}
                                    required
                                />
                                <p className="text-xs italic mt-4">Maximum 20 characters</p>
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                Page description
                            </label>
                            <p className="mt-3 text-sm leading-6 text-gray-600">This is the description line that appears in widgets and lists.</p>
                            <div className="mt-2">
                                <textarea
                                    id="description"
                                    name="description"
                                    rows={2}
                                    maxLength={20}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={displayAttributes.pageDescription}
                                    onChange={handleDescriptionChange}
                                    required
                                />
                                <p className="text-xs italic mt-4">Maximum 20 characters</p>
                            </div>
                        </div>


                        {/* Checkbox for enabling action */}
                        <div className="col-span-full">
                            <label htmlFor="enable-action" className="flex items-center text-sm font-medium leading-6 text-gray-900">
                                <input
                                    type="checkbox"
                                    id="enable-action"
                                    checked={displayAttributes.actionEnabled}
                                    onChange={handleActionEnabledCheckboxChange}
                                    className="mr-2"
                                />
                                Enable action button
                            </label>
                            {/* Dropdown select */}
                            {isActionEnabled && (
                                <div className="my-4">
                                    <select
                                        id="action-select"
                                        value={selectedAction}
                                        onChange={handleActionSelectChange}
                                        className="mb-1.5 rounded-md w-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        {dropdownOptions.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
