import { useState, useEffect } from 'react'
import Cookies from 'js-cookie';

/* Components */
import TableView from '../components/TableView/TableView';
import CreateAside from '../components/Asides/CreateAside';

export default function Forms({ context }) {
    const authToken = Cookies.get('auth_token');
    const [formData, setFormData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isCreateFormDialogOpen, setIsCreateFormDialogOpen] = useState(false);
    
    // Function to handle opening the dialog
    const openCreateFormDialog = () => {
        setIsCreateFormDialogOpen(true);
    };

    // Function to handle closing the dialog
    const closeCreateFormDialog = () => {
        setIsCreateFormDialogOpen(false);
    };

    const handleDeleteSuccess = (itemId) => {
        const updatedFormData = formData.filter((item) => item.uid !== itemId);
        setFormData(updatedFormData);
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch('/api/forms', {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${authToken}`, // Replace with your authentication token
                },
            });

            if (response.ok) {
                const responseData = await response.json();
                const formattedData = responseData.map(item => ({
                    ...item,
                    created_date: new Date(item.created_date).toLocaleDateString(), // Format the created_date
                    last_modified_date: new Date(item.last_modified_date).toLocaleDateString(), // Format the last_modified_date
                }));
                setFormData(formattedData);
                setIsLoading(false);
            } else {
                // Handle errors here
                console.error('Request failed with status:', response.status);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleFormCreate = async () => {
        await fetchData();
        console.log('Data refreshed');
    };

    useEffect(() => {
        fetchData(); 
    }, [authToken]);

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">
                    <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                        {formData && (
                            <TableView context={context} data={formData} onAddButtonClick={openCreateFormDialog} isLoading={isLoading} onDeleteSuccess={handleDeleteSuccess}/>
                        )}
                    </div>
                </div>
            </main>
            <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-l border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block">
            </aside>

            {/* Form */}
            {isCreateFormDialogOpen && (
                <CreateAside
                    type="form"
                    onClose={closeCreateFormDialog}
                    onCreate={handleFormCreate}
                    isDialogOpen={isCreateFormDialogOpen}
                />
            )}

        </>
    )
}